import React from 'react';
import { downloadPdf } from 'helpers/utils';
import wiwya from 'assets/images/gpn/experiences/wiwya.png';
import fgb from 'assets/images/gpn/experiences/fgb.png';
import wwd from 'assets/images/gpn/experiences/wwd.png';
import ttm from 'assets/images/gpn/experiences/ttm.png';
import gf from 'assets/images/gpn/experiences/gf.png';
import ic from 'assets/images/gpn/experiences/ic.png';
import cp from 'assets/images/gpn/experiences/cp.png';

const experiences = [
  {
    ftr_photo: gf,
    title: 'SALES PITCH PRO',
    text: 'Our cutting-edge VR experience that teaches you how to master the art of convincing clients—start boosting your sales skills and closing deals like a pro.',
    file_link: '',
  },
  {
    ftr_photo: ttm,
    title: 'TALK TO ME',
    text: 'Immerse yourself in a revolutionary VR experience that redefines self-reflection and problem-solving by engaging in a profound dialogue with yourself.',
    file_link: 'VRExperiences/Talk_to_Me.zip',
  },
  {
    ftr_photo: ic,
    title: 'STRATEGIC LEADERSHIP',
    text: 'Step into the role of a leader in our immersive VR experience, where you"ll learn to convincingly present a crucial project to your team. Develop your communication and leadership skills, and become a courageous and inspiring leader',
    file_link: '',
  },
  {
    ftr_photo: gf,
    title: 'GIVING FEEDBACK',
    text: 'Master the art of giving feedback with our immersive VR experience, where you practice delivering constructive critiques in various realistic scenarios.',
    file_link: '',
  },
  {
    ftr_photo: wiwya,
    title: 'WHEN I WAS YOUR AGE',
    text: 'Step into the shoes of a different age group with our immersive VR experience and gain a firsthand understanding of their daily work struggles.',
    file_link: 'VRExperiences/When_I_Was_Your_Age.zip',
  },
  {
    ftr_photo: fgb,
    title: 'FACE GENDER BIAS',
    text: 'Join Anna in our immersive VR experience to confront gender bias firsthand and witness the daily struggles she faces, inspiring you to become a champion for gender equality today!',
    file_link: 'VRExperiences/Face_Gender_Bias.zip',
  },
  {
    ftr_photo: wwd,
    title: 'WORKING WITH DISABILITY',
    text: 'Experience life through the eyes of an avatar with an invisible illness in our immersive VR simulation, and gain a profound understanding of the daily challenges they face.',
    file_link: 'VRExperiences/Working_With_Disabilities.zip',
  },
  {
    ftr_photo: cp,
    title: 'CHANGING PERSPECTIVE',
    text: 'Embody an avatar of a different ethnicity in our immersive VR experience and reduce your unconscious bias by up to 50%—start your journey towards greater empathy and understanding today!',
    file_link: 'VRExperiences/Unconscious_Bias.zip',
  },
];
export const VRExperience = () => {
  return (
    <div className='flex flex-col w-full px-32 pt-96 relative lg:pt-80 lg:px-16 sm:!pt-10 sm:!px-4'>
      <div className='absolute top-0 left-0 right-0 w-full h-96 bg-blue overflow-hidden lg:h-80 sm:hidden'>
        <span className='absolute bg-white w-full h-full clip-ellipse' />
      </div>
      <div className='flex items-center'>
        <p className='text-[54px] text-blue uppercase font-extrabold mr-8 lg:text-4xl sm:!text-2xl sm:mr-4'>VR Experiences</p>
        <span className='flex-1 bg-orange h-3 lg:h-2 sm:!h-1' />
      </div>
      <div className='flex flex-wrap justify-flex-start gap-x-16 gap-y-20 mt-40 lg:mt-20 lg:gap-x-10 lg:gap-y-16 sm:!mt-10 sm:flex-col sm:!gap-y-10'>
        {experiences.map((i, k) => {
          return (
            <div className='flex flex-col w-[calc(33%-40px)] lg:w-[calc(50%-40px)] sm:!w-full' key={k}>
              <img src={i.ftr_photo} alt={i.title} />
              <div className='flex flex-col items-center mt-10 flex-1 lg:mt-6 sm:!mt-4'>
                <p className='text-blue font-bold text-center text-[22px] uppercase'>{i.title}</p>
                <p className='text-blue font-bold text-sm text-justify flex-1 my-6 px-6 lg:px-4 lg:my-4'>{i.text}</p>
                <button
                  onClick={() => downloadPdf(experiences[k].file_link)}
                  className={`uppercase p-2.5 !leading-none bg-orange rounded-xl text-white duration-200	border-2 border-orange min-lg:hover:bg-white min-lg:hover:text-orange min-lg:!hover:delay-0 xl:text-base sm:w-full ${i.file_link.length === 0 ? 'opacity-50 pointer-events-none' : ''}`}>
                  {i.file_link.length === 0 ? 'COMING SOON' : 'DOWNLOAD CONTENT PACKAGE'}
                </button>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
