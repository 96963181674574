import React, { createContext, useEffect, useState } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [isVisibleModal, setIsVisibleModal] = useState(false);

  useEffect(() => {
    const isSignedIn = localStorage.getItem('isSignedIn');
    setIsVisibleModal(!isSignedIn);
  }, []);

  return (
    <ModalContext.Provider value={{ isVisibleModal, setIsVisibleModal }}>
      {children}
    </ModalContext.Provider>
  );
};
