import React from 'react';
import {downloadPdf} from 'helpers/utils';
import adidas from 'assets/images/gpn/caseStudies/adidas.png';
import bmw from 'assets/images/gpn/caseStudies/BMW.png';
import accenture from 'assets/images/gpn/caseStudies/accenture.png';
import airbus from 'assets/images/gpn/caseStudies/airbus.png';
import puma from 'assets/images/gpn/caseStudies/puma.png';
import siemens from 'assets/images/gpn/caseStudies/siemens.png';
import skyguide from 'assets/images/gpn/caseStudies/skyguide.png';
import wagner from 'assets/images/gpn/caseStudies/wagner.png';

const caseStudies = [
  {
    file_link: '',
    img: puma,
  },
  {
    file_link: 'caseStudies/Case_Study-Adidas.zip',
    img: adidas,
  },
  {
    file_link: '',
    img: skyguide,
  },
  {
    file_link: 'caseStudies/Case_Study-Accenture_x_wondder.pdf',
    img: accenture,
  },
  {
    file_link: '',
    img: siemens,
  },
  {
    file_link: '',
    img: bmw,
  },
  {
    file_link: '',
    img: airbus,
  },
  {
    file_link: '',
    img: wagner,
  },
];

export const CaseStudies = () => {
  return (
    <div className='flex flex-col w-full relative py-12 px-40 mt-40 lg:px-16 lg:mt-20 sm:!px-4 sm:!mt-10'>
      <div className='flex items-center'>
        <p className='text-[54px] text-blue uppercase font-extrabold mr-8 lg:text-4xl sm:!text-2xl sm:mr-4'>Case Studies</p>
        <span className='flex-1 bg-orange h-3 lg:h-2 sm:!h-1' />
      </div>
      <div className='flex flex-wrap gap-y-14 mt-40 self-center lg:mt-20 sm:!mt-10'>
        {caseStudies.map((i, k) => {
          return (
            <div className='flex flex-col items-center flex-[0_1_33.3%] sm:flex-[0_1_50%]' key={k}>
              <img className='mb-8 rounded-full w-52 h-52 overflow-hidden md:w-40 md:h-40 sm:!w-32 sm:!h-32 sm:mb-5' src={i.img} alt='partner logo' />
              <button onClick={() => downloadPdf(caseStudies[k].file_link)} className={`text-sm uppercase p-2.5 !leading-none bg-orange rounded-xl text-white duration-200	border-2 border-orange min-lg:hover:bg-white min-lg:hover:text-orange min-lg:!hover:delay-0 xl:text-base ${i.file_link.length === 0 ? 'opacity-50 pointer-events-none':''}`}>{i.file_link.length === 0 ? 'COMING SOON': 'DOWNLOAD'}</button>
            </div>
          );
        })}
      </div>
    </div>
  );
};
